import React, { useState, useEffect, useRef } from 'react';
import axios from "axios";
import { BASE_URL } from '../../Utils/Config';
import { Carousel } from 'flowbite-react';
import './Card2.css';
import Logo26 from "../../../src/assets/images/Frame 34.png";
import arrowLeft from "../../../src/assets/images/arrow-right-01-round.png";
import arrowRight from "../../../src/assets/images/arrow-right-02-round.png";
import Logo6 from "../../../src/assets/images/image3.png";
import Logo7 from "../../../src/assets/images/eclips.png";
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import { motion, useAnimation, useInView } from 'framer-motion';
const Card2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [events, setEvents] = useState([]);
  const [news, setNews] = useState([]);
  const controls = useAnimation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      controls.start({ opacity: 1, y: 0 });
    } else {
      controls.start({ opacity: 0, y: 50 });
    }
  }, [isInView, controls]);


  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const [generalEventsResponse, eventsResponse] = await Promise.all([
          axios.get(`${BASE_URL}/admins/general_eventslistb/`),
          axios.get(`${BASE_URL}/admins/eventslistb/`)
        ]);

        if (generalEventsResponse.status !== 200 || eventsResponse.status !== 200) {
          throw new Error('Failed to fetch events');
        }

        const generalEventsData = generalEventsResponse.data || {};
        const eventsData = eventsResponse.data || {};
        console.log("generalEventsData", generalEventsData);
        console.log("eventsData", eventsData);

        const combinedEvents = {
          completed_events: [...(eventsData.completed_events || []), ...(generalEventsData.completed_events || [])],
          live_events: [...(eventsData.live_events || []), ...(generalEventsData.live_events || [])],
          upcoming_events: [...(eventsData.upcoming_events || []), ...(generalEventsData.upcoming_events || [])],
        };

        setEvents(combinedEvents);
      } catch (error) {
        console.error('Error fetching events:', error.response ? error.response.data : error.message);
      }
    };

    fetchEvents();
  }, []);



  const handleEventStatusClick = (index) => {
    setCurrentIndex(index);
  };

  const renderBanners = () => {
    if (!events || !events.completed_events || !events.live_events || !events.upcoming_events) {
      return null;
    }

    let filteredEvents;
    if (currentIndex === 0) {
      filteredEvents = events.completed_events;
    } else if (currentIndex === 1) {
      filteredEvents = events.live_events;
    } else if (currentIndex === 2) {
      filteredEvents = events.upcoming_events;
    } else {
      filteredEvents = [];
    }

    return filteredEvents.map((event, index) => {
      const imgUrl = event.banner_image
        ? `${BASE_URL}${event.banner_image.startsWith('/') ? event.banner_image : `/${event.banner_image}`}`
        : '';

      console.log(`Banner ${index} URL:`, imgUrl);

      return (
        <Link to={`/events-detail/${event.slug}/`} key={index}>
          {console.log("Event slug:", event.slug)}
          <img
            src={imgUrl}
            alt={`Banner ${index}`}
            className="rounded-[20px] w-full object-cover h-full"
          />
        </Link>
      );
    });
  };



  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/admins/news/`);
        setNews(response.data);
        console.log("news", response.data)
      } catch (error) {
        console.error('Error fetching news:', error);
      }
    };

    fetchNews();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const { ref: carouselRef, inView: isCarouselInView } = useInView({ triggerOnce: true });
  const { ref: newsRef, inView: isNewsInView } = useInView({ triggerOnce: true });

  return (
    <div className="w-full h-full bg-[#f4f4f4]">
      <div className="container ">
        <motion.div
          className=" "
          initial={{ opacity: 0 }}
          animate={{ opacity: 4 }}
          transition={{
            duration: 1.5,
            ease: "easeInOut",
          }}
          ref={ref}
        >
          <div className="flex mob-flex mob-flex222 mb-mt20 gap-6">

            <div className=" mob-pb-20 md:w-[100%] lg:w-[40%] xl:w-[40%] m-w-1000 rounded-[30px] bg-[#00549a] pall-15">
              <div className='eventhead'>
                <h4 className='event-head'>Events</h4>
                <div className="ml-auto">
                  <Link to='/events'>
                    <motion.div
                      initial={{ rotate: 0 }}
                      animate={{ rotate: 360 }}
                      transition={{ duration: 2, ease: "easeInOut" }}
                    >
                      <img src={Logo26} alt="Logo" className="rounded-[30px]" />
                    </motion.div>
                  </Link>
                </div>
              </div>
              <div className="flex rounded-[30px]">
                <div className="">

                  <button
                    className={`justify-center items-center px-7 py-3 m-padding rounded-[105px] border mtp10 transition-colors duration-300 ${currentIndex === 2 ? 'border-transparent' : ''}`}
                    style={{ width: 'auto', backgroundColor: currentIndex === 2 ? '#1ab78d' : '', transition: 'background-color 0.8s ease' }}
                    onClick={() => handleEventStatusClick(2)}
                  >
                    <p className="text-white text-[14px] font-semibold">Upcoming <span className='m-640-none'>Events</span></p>
                  </button>

                  <button
                    className={`justify-center items-center ml-3 px-7 py-3 m-padding rounded-[105px] border transition-colors duration-300 ${currentIndex === 1 ? 'border-transparent' : ''}`}
                    style={{ width: 'auto', backgroundColor: currentIndex === 1 ? '#1ab78d' : '', transition: 'background-color 0.8s ease' }}
                    onClick={() => handleEventStatusClick(1)}
                  >
                    <p className="text-white text-[14px] font-semibold">Live <span className='m-640-none'>Events</span></p>
                  </button>

                  <button
                    className={`justify-center items-center ml-3 px-7 py-3 m-padding rounded-[105px] border transition-colors duration-300 ${currentIndex === 0 ? 'border-transparent' : 'border-white'}`}
                    style={{ width: 'auto', backgroundColor: currentIndex === 0 ? '#1ab78d' : '', transition: 'background-color 0.8s ease' }}
                    onClick={() => handleEventStatusClick(0)}
                  >
                    <p className="text-white text-[14px] font-semibold">Past <span className='m-640-none'>Events</span></p>
                  </button>


                </div>


              </div>
              <motion.div
                className="flex justify-center items-center home-h-56 home-sm:h-64 home-md:h-72 home-lg:h-72 home-xl:h-80 home-2xl:h-96 mar-top-events "
                initial={{ opacity: 0 }}
                animate={{ opacity: 4 }}
                transition={{
                  duration: 1,
                  ease: "easeInOut",
                }}
                ref={ref}
              >

                <Carousel
                  indicators={false}
                  className=" "
                  prevIcon={<img src={arrowLeft} alt="Previous" />}
                  nextIcon={<img src={arrowRight} alt="Next" />}
                  transitionTime={500}
                  swipeable={true}
                  infiniteLoop={true}
                >
                  {renderBanners()}
                </Carousel>
              </motion.div>

            </div>


            <div className="md:w-[100%] lg:w-[60%] xl:w-[60%] m-w-1000 mt-m-10 mt-6 lg:mt-0">
              <div className="justify-center rounded-[30px] bg-white pall-p-20">


                <div className="pb-deskk-20">

                  <motion.div
                    className="pb-deskk-20"
                    initial={{ opacity: 0, y: 50 }}
                    animate={controls}
                    transition={{ duration: 1 }}
                    ref={ref}
                  >
                    <div className="flex justify-between items-center mb-m-2">
                      <p className="text-[#222] text-start text-[32px] not-italic font-bold leading-[normal]">
                        What’s New
                      </p>


                      <motion.div
                        initial={{ rotate: 0 }}
                        animate={{ rotate: 360 }}
                        transition={{ duration: 1, ease: "easeInOut" }}
                      >
                        <Link to="/newspage">
                          <img src={Logo26} alt="Logo" className="rounded-[30px]" />
                        </Link>
                      </motion.div>
                    </div>

                    {news.slice(-5).reverse().map((item, index) => (
                      <div key={index} className={`flex pt-2 items-center mob-blockdiv1 mborder`}>
                        <img src={Logo6} alt="Logo" className="mt-4" />
                        <div className="flex items-center mob-blockdiv justify-between w-[100%] p-ll-88">
                          <p className="text-[18px] text-[#58585A] font-medium pt-4 news-text-width news-content">
                        {parse(item.text.length > 100 ? `${item.text.substring(0, 100)}...` : item.text)}

                          </p>
                          <p className="text-[16px] pt-2 text-[#58585A] font-bold w-120">
                            {formatDate(item.date)}
                          </p>
                        </div>
                      </div>
                    ))}
                  </motion.div>

                </div>
              </div>

            </div>


          </div>
          </motion.div>
      </div>
    </div>
  );
};

export default Card2;
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Link, useParams, useLocation, useNavigate } from 'react-router-dom';
import { BASE_URL } from '../../../Utils/Config';
import Logo1 from "../../../assets/images/calendar.png";
import arrow2 from "../../../assets/images/ev-1.png";
import arrow1 from "../../../assets/images/ev.png";
import lin from "../../../assets/images/linkdn.png";
import facebook from "../../../assets/images/face.png";
import twit from "../../../assets/images/twit.png";
import insta from "../../../assets/images/insta.png";
import yout from "../../../assets/images/youtu.png";
import cancel from "../../../assets/images/speak cac.png";
import { getLocal } from '../../../helpers/auth';
import { isTokenExpired } from '../../../helpers/auth';
import { motion, useAnimation, useInView } from 'framer-motion';


const Detail = () => {
    const { slug } = useParams();
    const location = useLocation();

    console.log("slug", slug)
    const [event, setEvent] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [enrolled, setEnrolled] = useState(false);
    const [watchNowVisible, setWatchNowVisible] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [speakers, setSpeakers] = useState([]);
    const speakersPerPage = 4;
    const indexOfLastSpeaker = currentPage * speakersPerPage;
    const indexOfFirstSpeaker = indexOfLastSpeaker - speakersPerPage;
    const currentSpeakers = speakers.slice(indexOfFirstSpeaker, indexOfLastSpeaker);
    const [selectedSpeaker, setSelectedSpeaker] = useState(null); // State for the selected speaker
    const [isModalOpen, setIsModalOpen] = useState(false);
    const controls = useAnimation();
    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });
    const [liveEvents, setLiveEvents] = useState([]);
    const [upcomingEvents, setUpcomingEvents] = useState([]);
    const [completedEvents, setCompletedEvents] = useState([]);

    useEffect(() => {
        if (isInView) {
            controls.start({ opacity: 1, y: 0 });
        } else {
            controls.start({ opacity: 0, y: 50 });
        }
    }, [isInView, controls]);
    const goToNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const handleSpeakerClick = (speaker) => {
        setSelectedSpeaker(speaker);
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSelectedSpeaker(null);
    };

    const goToPrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    useEffect(() => {
        const authToken = localStorage.getItem('authToken');
        if (authToken) {
            setIsLoggedIn(true);
        }
    }, []);

    useEffect(() => {
        console.log("slug:", slug);

        const fetchSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/`, {

                });
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchSingleEvent();
    }, [slug]);
    useEffect(() => {
        console.log("slug:", slug);

        const fetchGeneralSingleEvent = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/generalevents/${slug}/`, {

                });
                setEvent(response.data);
            } catch (error) {
                console.error('Error fetching single event:', error);
            }
        };

        fetchGeneralSingleEvent();
    }, [slug]);


    useEffect(() => {
        const fetchEvents = async () => {
            try {
                const [eventsResponse, generalEventsResponse] = await Promise.all([
                    axios.get(`${BASE_URL}/admins/eventslist/`),
                    axios.get(`${BASE_URL}/admins/general_events/`)
                ]);

                console.log("Events Response:", eventsResponse.data);
                console.log("General Events Response:", generalEventsResponse.data);

                // Combine events data
                const combinedLiveEvents = [
                    ...eventsResponse.data.live_events,
                    ...generalEventsResponse.data.live_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                const combinedUpcomingEvents = [
                    ...eventsResponse.data.upcoming_events,
                    ...generalEventsResponse.data.upcoming_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                const combinedCompletedEvents = [
                    ...eventsResponse.data.completed_events,
                    ...generalEventsResponse.data.completed_events
                ].sort((a, b) => new Date(b.end_date) - new Date(a.end_date));

                setLiveEvents(combinedLiveEvents);
                setUpcomingEvents(combinedUpcomingEvents);
                setCompletedEvents(combinedCompletedEvents);

            } catch (error) {
                console.error('Error fetching events:', error);
            }
        };

        fetchEvents();
    }, []);

    const checkEnrollmentStatus = async () => {
        try {
            if (event) {
                const response = await axios.get(`${BASE_URL}/accounts/check-enrollment/${event.slug}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                setEnrolled(response.data.enrolled);

                const today = new Date();
                const eventDate = new Date(event.date);
                const eventEndDate = new Date(eventDate);
                eventEndDate.setHours(23, 59, 59, 999);

                const hasEnded = today > eventEndDate;


                const isLive = liveEvents.some(liveEvent => liveEvent.slug === event.slug);


                const isCompleted = completedEvents.some(completedEvent => completedEvent.slug === event.slug);

                if (response.data.enrolled) {
                    if (isCompleted) {

                        setWatchNowVisible(true);
                    } else if (isLive) {

                        setWatchNowVisible(true);
                    } else {

                        setWatchNowVisible(false);
                    }
                } else {

                    setWatchNowVisible(false);
                }
            }
        } catch (error) {
            console.error('Error checking enrollment status:', error);
        }
    };





    const [generalEnrolled, setGeneralEnrolled] = useState(false);


    const checkgeneralEnrollmentStatus = async () => {
        try {
            if (event) {
                const response = await axios.get(`${BASE_URL}/accounts/general_check-enrollment/${event.slug}/`, {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('authToken')}`
                    }
                });
                setGeneralEnrolled(response.data.enrolled);

                const today = new Date();
                const eventDate = new Date(event.date);
                const eventEndDate = new Date(eventDate);
                eventEndDate.setHours(23, 59, 59, 999);


                const hasEnded = today > eventEndDate;

                const isLive = liveEvents.some(liveEvent => liveEvent.slug === event.slug);


                const isCompleted = completedEvents.some(completedEvent => completedEvent.slug === event.slug);


                if (response.data.enrolled) {
                    if (isCompleted) {

                        setWatchNowVisible(true);
                    } else if (isLive) {

                        setWatchNowVisible(true);
                    } else {

                        setWatchNowVisible(false);
                    }
                } else {

                    setWatchNowVisible(false);
                }
            }
        } catch (error) {
            console.error('Error checking general enrollment status:', error);
        }
    };



    useEffect(() => {

        checkEnrollmentStatus();
        checkgeneralEnrollmentStatus();


        const intervalId = setInterval(checkEnrollmentStatus, 10000); // Check every 60 seconds

        // Cleanup interval on component unmount
        return () => clearInterval(intervalId);
    }, [isLoggedIn, event, liveEvents, completedEvents]);


    const navigate = useNavigate();
    const handleLoginClick = () => {
        const currentPath = window.location.pathname;
        localStorage.setItem('loginRedirectPath', currentPath);
        navigate('/login');
    };



    const enrollInEvent = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/accounts/enroll/${event?.slug}/`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });

            // Update the enrolled state based on the response
            if (response.data.detail.includes("Successfully enrolled")) {
                setEnrolled(true);
                return "Successfully enrolled in the event."; // Return the success message
            } else if (response.data.detail.includes("You are already enrolled")) {
                return "You are already enrolled in this event."; // Return the already enrolled message
            }

            return response.data.detail; // Return the detail for any other message
        } catch (error) {
            console.error('Error handling enrollment click:', error);
            return 'Failed to update enrollment status. Please try again later.';
        }
    };

    const generalEnrollInEvent = async () => {
        try {
            const response = await axios.post(`${BASE_URL}/accounts/general_enroll/${event?.slug}/`, {}, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('authToken')}`
                }
            });


            if (response.data.detail.includes("Successfully enrolled")) {
                setGeneralEnrolled(true);

                return "Successfully enrolled in the general event.";
            } else if (response.data.detail.includes("You are already enrolled")) {
                return "You are already enrolled in this general event.";
            }

            return response.data.detail;
        } catch (error) {
            console.error('Error enrolling in the general event:', error);
            return 'Failed to enroll in the general event. Please try again later.';
        }
    };







    useEffect(() => {
        const fetchEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchEventSpeakers();
    }, [slug]);

    useEffect(() => {
        const fetchGeneralEventSpeakers = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/admins/general_events/${slug}/speakers/`);
                if (response.data && Array.isArray(response.data.speakers)) {
                    setSpeakers(response.data.speakers);

                } else {
                    console.error('Invalid data format for speakers:', response.data);
                }
            } catch (error) {
                console.error('Error fetching event speakers:', error);
            }
        };

        fetchGeneralEventSpeakers();
    }, [slug]);

    const formatTime = (time) => {
        if (!time) return '';
        const [hours, minutes] = time.split(':');
        const formattedHours = parseInt(hours) > 12 ? parseInt(hours) - 12 : parseInt(hours);
        return `${formattedHours}:${minutes} ${parseInt(hours) >= 12 ? 'PM' : 'AM'}`;
    };



    const handleJoinFCPI = () => { };

    const handleWatchEvent = () => { };
    const { id } = useParams();
    const [speaker, setSpeaker] = useState(null);
    useEffect(() => {
        const fetchSpeaker = async () => {
            try {
                const response = await axios.get(`${BASE_URL}/speakers/${id}/`);
                setSpeaker(response.data);
            } catch (error) {
                console.error('Error fetching speaker:', error);
            }
        };

        fetchSpeaker();
    }, [id]);
    const handleEnrollClick = async () => {
        try {
            const enrollPromise = enrollInEvent();
            const generalEnrollPromise = generalEnrollInEvent();

            const [enrollMessage, generalEnrollMessage] = await Promise.all([enrollPromise, generalEnrollPromise]);


            let watchNowVisibleFlag = false;
            let isEnrolledEvent = false;
            let isGeneralEnrolledEvent = false;


            if (enrollMessage.includes("Successfully enrolled")) {
                alert(enrollMessage);
                isEnrolledEvent = true;
                watchNowVisibleFlag = true;
            } else if (enrollMessage.includes("You are already enrolled")) {
                alert("You are already enrolled in this event.");
            } else if (enrollMessage.includes("Unenrolled")) {
                alert("You have been un-enrolled from this event.");
                isEnrolledEvent = false;
                watchNowVisibleFlag = false;
            }


            if (generalEnrollMessage.includes("Successfully enrolled")) {
                alert(generalEnrollMessage);
                isGeneralEnrolledEvent = true;
                watchNowVisibleFlag = true;
            } else if (generalEnrollMessage.includes("You are already enrolled")) {
                alert("You are already enrolled in this general event.");
            } else if (generalEnrollMessage.includes("Unenrolled")) {
                alert("You have been un-enrolled from this general event.");
                isGeneralEnrolledEvent = false;
                watchNowVisibleFlag = false;
            }


            setEnrolled(isEnrolledEvent);
            setGeneralEnrolled(isGeneralEnrolledEvent)

            setWatchNowVisible(watchNowVisibleFlag);


            await checkEnrollmentStatus();

        } catch (error) {
            console.error('Error handling enrollment click:', error);
            alert('Failed to enroll in the event. Please try again later.');
        }
    };








    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0'); // Pad with zero if day < 10
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Pad with zero if month < 10
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    };




    return (
        <div className="w-full h-full bg-[#f4f4f4]">
            <div className="container">
                <div className='pt-6'>
                    <div className='bg-white rounded-[30px] p-all-20'>
                        <div className='overflow-hidden relative rounded-[30px]'>
                            <div className='aspect-[4] bg-card3-background bg-cover to-slate-900 bg-opacity-40 gradient responsive-card rounded-[30px]'>
                                {event && (
                                    <>
                                        <div className='flex relative z-10 pl-12 pt-32'>
                                            <p className='evenet-m-name '>{event.event_name}</p>
                                        </div>
                                        <p className='evenet-m-subname '>{event.forum_name}</p>
                                        <hr className='mt-8 w-[95%] m-full-w ml-9 relative z-10' />
                                        <div className='flex relative z-10 p-10 pl-12 gap-8'>
                                            <div className='flex content-center gap-4'>
                                                <div>
                                                    <img src={Logo1} alt="Calendar" className="" />
                                                </div>
                                                <div className='flex  gap-evn-1'>
                                                    {event.days === 1 ? (

                                                        <>
                                                            <p className='text-white pt-2 text-[17px] mm-0 not-italic font-normal leading-[normal]'>
                                                                {formatDate(event.date)}
                                                            </p>
                                                            {event.single_events.map((singleEvent, index) => (
                                                                <div key={index} className='  rounded-[10px]   pt-1 mb-3'>
                                                                    <div className="flex">
                                                                        {singleEvent.multi_events.length > 0 && (
                                                                            <div className="flex">
                                                                                <p className="text-[#58585A]   text-[17px] not-italic font-normal leading-[normal]">
                                                                                    {singleEvent.multi_events.length > 0 && (
                                                                                        <div className="flex">
                                                                                            <p className="text-white pt-1 text-[17px] not-italic font-normal leading-[normal]">
                                                                                                {`${formatTime(singleEvent.multi_events[0].starting_time)} - ${formatTime(singleEvent.multi_events[singleEvent.multi_events.length - 1].ending_time)}`} (IST)
                                                                                            </p>
                                                                                        </div>
                                                                                    )}
                                                                                </p>
                                                                            </div>
                                                                        )}


                                                                    </div>

                                                                </div>
                                                            ))}
                                                        </>
                                                    ) : (

                                                        <>
                                                            <p className='text-white pt-2 text-[17px] mm-0 not-italic font-normal leading-[normal]'>
                                                                {`${formatDate(event.start_date)}  -  ${formatDate(event.end_date)}`}
                                                            </p>

                                                        </>
                                                    )}



                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className='pl-12 pr-12 pt-16'>
                            <p className='text-[#222] text-[35px] not-italic font-semibold leading-[normal]'>Speaker Desk</p>
                            <div className='flex justify-between '>
                                <p className='text-[#58585A] pt-7 text-[17px] not-italic font-normal leading-[28px]'>
                                    {/* Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web */}
                                    <br />
                                    {/* sites still in their infancy. Various versions have evolved */}
                                </p>
                                <div className='text-end mm-t-15 '>
                                    <button onClick={goToPrevPage} disabled={currentPage === 1}>
                                        <img src={arrow1} alt="Previous Page" className='pl-32' />
                                    </button>
                                    <button onClick={goToNextPage} disabled={indexOfLastSpeaker >= speakers.length}>
                                        <img src={arrow2} alt="Next Page" className='pl-2' />
                                    </button>
                                </div>
                            </div>
                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 pb-8 gap-8">
                                {currentSpeakers.map((speaker, index) => (
                                    speaker ? (

                                        <motion.div
                                            className="relative group"
                                            initial={{ opacity: 0 }}
                                            animate={{ opacity: 4 }}
                                            transition={{
                                                duration: 2,
                                                ease: "easeInOut",
                                            }}
                                            ref={ref}
                                        >
                                            <Link to="#">
                                                <div
                                                    className="bg-gray-100 hover:bg-gray-200 rounded-[10px] p-4"
                                                    onClick={() => handleSpeakerClick(speaker)}
                                                >
                                                    <img
                                                        src={BASE_URL + (speaker.photo || '')}
                                                        alt={speaker.name || 'Speaker'}
                                                        className="speaker-img object-cover rounded-[10px]"
                                                    />
                                                    <p className="text-black text-center pt-3 pb-3 text-[18px] not-italic font-semibold leading-[18px]">
                                                        {speaker.name || 'No Name Available'}
                                                    </p>
                                                </div>
                                            </Link>
                                            {/* Tooltip */}
                                            <div className="absolute bottom-full -mb-40 left-1/2   transform -translate-x-1/2 hidden group-hover:block bg-gray-800 text-white text-sm rounded px-2 py-1 whitespace-nowrap">
                                                {speaker.tooltip || 'Click Here to View Profile '}
                                            </div>
                                        </motion.div>
                                    ) : (
                                        <div key={index} className="bg-gray-100 rounded-[10px] text-center py-8">
                                            <p className="text-black text-center text-[18px]">Speaker data is missing</p>
                                        </div>
                                    )
                                ))}


                            </div>
                            {isModalOpen && selectedSpeaker && (

                                <motion.div
                                    className="fixed  inset-0 flex items-center justify-center mt-50 bg-black bg-opacity-50"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 4 }}
                                    transition={{
                                        duration: 1,
                                        ease: "easeInOut",
                                    }}
                                    ref={ref}
                                >
                                    <div className='bg-white p-8 rounded-[12px] w-[780px]  '>
                                        <div className='flex justify-between '>

                                            <p className='text-[24px] font-semibold'>Speaker Desk</p>

                                            <div>

                                                <img src={cancel} alt="" onClick={handleCloseModal} />
                                            </div>
                                        </div>
                                        <hr className='mt-6' />

                                        <div className='flex pt-8'>
                                            <div className=' '>
                                                <img src={BASE_URL + selectedSpeaker?.photo} alt="" className=" w-[160px] h-[145px] object-cover rounded-[10px]" />
                                            </div>
                                            <div className='mt-4 pl-6 w-[100%]'>
                                                <p className='text-[20px] pt-2  text-[#00549A] font-bold'>{selectedSpeaker.name}</p>
                                                <hr className='mt-4' />
                                                <p className='text-[16px] pt-4 font-semibold'>Qualification: {selectedSpeaker.qualification}</p>
                                                <p className='text-[13px]'> Designation: {selectedSpeaker.designation}</p>

                                            </div>

                                        </div>
                                        <p className='text-[13px] pt-6'> {selectedSpeaker.description}</p>
                                        <hr className='mt-6' />
                                        <div className='flex space-x-4 pt-6 '>
                                            {selectedSpeaker.facebook && (
                                                <a href={selectedSpeaker.facebook} target="_blank" rel="noopener noreferrer">
                                                    <img src={facebook} alt="Facebook" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.linkedin && (
                                                <a href={selectedSpeaker.linkedin} target="_blank" rel="noopener noreferrer">
                                                    <img src={lin} alt="LinkedIn" className="" />
                                                </a>
                                            )}
                                            {selectedSpeaker.twitter && (
                                                <a href={selectedSpeaker.twitter} target="_blank" rel="noopener noreferrer">
                                                    <img src={twit} alt="twitter" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.instagram && (
                                                <a href={selectedSpeaker.instagram} target="_blank" rel="noopener noreferrer">
                                                    <img src={insta} alt="instagram" className=" " />
                                                </a>
                                            )}
                                            {selectedSpeaker.youtube && (
                                                <a href={selectedSpeaker.youtube} target="_blank" rel="noopener noreferrer">
                                                    <img src={yout} alt="youtube" className=" " />
                                                </a>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                        </div>
                        <div className='p-5 pl-12'>
                            <div className='flex enrl-mob-block'>
                                <div className='rounded-[10px] text-left w-[400px] w-en-full p-10' style={{ backgroundColor: 'rgba(197, 229, 255, 0.50)' }}>
                                    <p className='text-[#222] text-[30px] not-italic font-semibold leading-[normal] text-center'>
                                        {isLoggedIn ? "Event Enrollment " : "If you are not Logged In FCPI Please"}
                                    </p>

                                    <div className='enrld pt-4'>
                                        {enrolled && <p>You Are Enrolled</p>}
                                    </div>
                                    <div className='flex pt-2 pl-2 m-m-pl-0 mob-center'>
                                        {!isLoggedIn ? (
                                            <>
                                                <button
                                                    onClick={handleLoginClick}
                                                    className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A]"
                                                >
                                                    <p className="text-[14px] not-italic font-medium leading-[normal]">Login</p>
                                                </button>
                                                <Link to='/register'>
                                                    <button
                                                        onClick={handleJoinFCPI}
                                                        className="flex justify-center items-center px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] border hover:border-[#1AB78D] border-[#00549A] ml-4"
                                                    >
                                                        <p className="text-[14px] not-italic font-medium leading-[normal]">Join FCPI</p>
                                                    </button>
                                                </Link>
                                            </>
                                        ) : (
                                            <>
                                                <div className='items-center block text-center m-auto'>
                                                    <button
                                                        onClick={handleEnrollClick}
                                                        className={`px-[29px] py-[7px] hover:bg-[#1AB78D] text-[#00549A] hover:text-white rounded-[105px] ${enrolled || generalEnrolled ? 'bg-red-500 border-none' : 'border hover:border-[#1AB78D] border-[#00549A]'}`}
                                                        disabled={enrolled && generalEnrolled}
                                                    >
                                                        <p className={`entrl-btn-text not-italic font-semibold leading-[normal] ${enrolled || generalEnrolled ? 'text-white' : ''}`}>
                                                            {enrolled || generalEnrolled ? "Disenroll" : "Enroll Now"}
                                                        </p>
                                                    </button>

                                                </div>

                                            </>
                                        )}
                                    </div>
                                </div>


                                <div className='entrl-w-btn w-[50%] w-en-full m-entrl-center'>


                                    {watchNowVisible && (
                                        <Link to={`/enroll/${event?.slug}/`}>
                                            <button
                                                onClick={handleWatchEvent}
                                                className="watchnow-btn"
                                            >
                                                <p className="">Watch Now</p>
                                            </button>
                                        </Link>
                                    )}
                                </div>

                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Detail;
